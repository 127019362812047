<template>
  <div class="main-content">
    <section v-if="page == 1">
      <div class="verticalAlign">
        <div class="row">
          <div class="col-md-12 mb-3">
            <div class="alert alert-dark centered" role="alert">
              Aqui você poderá filtrar as posições de acordo com seu interesse,
              selecione os itens desejados e clique em "Filtrar Posições"
            </div>
          </div>
        </div>
        <div class="row">
          <div
            class="col centered"
            style="text-align: center; lg-check"
            v-for="detail in details"
            :key="detail.id"
          >
            <input
              type="checkbox"
              :value="detail.id"
              v-model="checkeds"
              :id="detail.id"
            />
            <label :for="detail.id">{{ detail.title }}</label>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-12 centered">
            <button class="btn btn-add" @click="selectPlace">
              <i class="fas fa-filter"></i> Filtrar Posições
            </button>
          </div>
        </div>
      </div>
    </section>
    <section v-if="page == 2">
      <div class="row" v-if="loadedBlueprint" style="width: 100%">
        <div class="col-md-12">
          <div
            class="top-container"
            style="border: 0px solid #f0f0f0; margin-top: -15px"
          >
            <img
              :src="available.workstation.blueprint"
              v-on:load="onLoaded"
              class="image-size img-responsive"
              style="top: 0; left: 0"
            />
            <div>
              <VueDragResize
                class="centered"
                v-for="(rect, index) in elements"
                :key="index"
                :w="rect.width"
                :h="rect.height"
                :x="rect.left"
                :y="rect.top"
                :axis="rect.axis"
                :isActive="rect.active"
                :minw="50"
                :minh="50"
                :isResizable="false"
                :isDraggable="false"
                :parentLimitation="true"
                :snapToGrid="rect.snapToGrid"
                :aspectRatio="rect.aspectRatio"
                :z="rect.zIndex"
                :contentClass="rect.class"
                :stickSize="rect.stickSize"
                @clicked="selectPosition(rect)"
              >
                <button
                  v-if="available.select_chair == 1"
                  class="btn btn-primary2"
                  data-toggle="tooltip"
                  data-placement="top"
                  :title="workstation_details_text"
                >
                  <i class="fas fa-chair"></i>
                </button>
                <button
                  v-else
                  class="btn btn-primary2"
                  data-toggle="tooltip"
                  data-placement="top"
                  :title="workstation_details_text"
                >
                  <span>{{ rect.title }}</span>
                </button>
              </VueDragResize>
            </div>
          </div>
        </div>
        <div class="col-md-12 centered mb-5">
          <button
            class="btn btn-add"
            @click="page = 1"
            v-if="force_filter == 1"
          >
            <i class="fas fa-filter"></i> Filtrar Posições
          </button>
        </div>
        <div
          class="col-md-12 centered image-size"
          style="
            position: absolute;
            z-index: 9999;
            height: 100%;
            background-color: #00000070;
          "
          v-if="pinch"
          @click="closePinch"
        >
          <img
            src="../assets/images/pinch_zoom.gif"
            style="width: 40%; margin-left: -120px"
            alt=""
          />
        </div>
        <div
          class="col-md-12 centered"
          style="
            margin-top: -23%;
            color: #fff;
            z-index: 9999;
            font-weight: bold;
          "
          @click="closePinch"
          v-if="pinch"
        >
          <span>Utilize o gesto de pinça para dar zoom na imagem.</span>
        </div>
      </div>
    </section>
  </div>
</template> 

<script>
import VueDragResize from "vue-drag-resize";
export default {
  data() {
    return {
      page: 2,
      loaded: false,
      force_filter: "",
      loadComponent: true,
      today: {},
      companies: [],
      filters: {
        company: "",
        workstation: "",
      },
      available: {},
      loadedBlueprint: false,
      checkeds: [],
      width: 0,
      height: 0,
      top: 0,
      left: 0,
      selected: "",
      elements: [],
      card_height: 0,
      inSelection: {},
      pinch: true,
      details: [],
    };
  },

  methods: {
    selectPosition(object) {
      window.flutter_inappwebview.callHandler(
        "handlerChoiceSeat",
        object.id,
        object.title
      );
    },
    onLoaded() {
      const self = this;
      self.elements = self.sizeTreatment(self.available.available);
    },
    selectPlace(object) {
      const self = this;
      const api = self.$store.state.api + "workstations/available_open";

      self.filters.company = self.$route.params.id;
      self.filters.date = self.$route.params.date;
      self.filters.workstation = self.$route.params.id;
      self.filters.time_start = self.$route.params.time_start;
      self.filters.time_end = self.$route.params.time_end;
      self.filters.checkeds = self.checkeds;
      self.filters.user_id = self.$route.params.user_id;

      self.loadedBlueprint = false;

      self.$_http
        .post(api, self.filters)
        .then((response) => {
          self.available = response.data;
          self.workstation_details_text =
            response.data.workstation_details_text;
          self.loadedBlueprint = true;
          self.page = 2;
          setTimeout(() => {
            self.closePinch();
          }, 3000);
        })
        .catch((error) => {
          self.errors = error.response.data;
          self.$message("error", self.errors, "error");
          window.flutter_inappwebview.callHandler(
            "handlerChoiceSeat",
            null,
            null
          );
        });
    },

    getConfiguration(object) {
      const self = this;
      const api = self.$store.state.api + "workstations/force_filter";

      self.filters.workstation = self.$route.params.id;

      self.$_http
        .post(api, self.filters)
        .then((response) => {
          self.force_filter = response.data.force_filter;
          self.details = response.data.details;
          if (self.force_filter == 1) {
            self.page = 1;
          } else {
            self.page = 2;
            self.selectPlace();
          }
        })
        .catch((error) => {
          self.errors = error.response.data;

          self.$message("error", error.response.data.message);
        });
    },
    resize: function (newRect, index) {
      this.elements[index].width = newRect.width;
      this.elements[index].height = newRect.height;
      this.elements[index].top = newRect.top;
      this.elements[index].left = newRect.left;
    },
    sizeTreatment: function (array) {
      const self = this;
      var array2 = [];
      array.forEach((detail, index) => {
        array2[index] = self.calculateDimensions(detail);
      });
      return array2;
    },
    calculateDimensions: function (obj) {
      let result = {};
      let imageSize = document.getElementsByClassName("image-size")[0];
      let width = imageSize.width;
      let height = imageSize.height;
      this.card_height = height;

      result.active = false;
      result.top = (obj.top * height) / obj.screen_height;
      result.left = (obj.left * width) / obj.screen_width;
      result.screen_width = width;
      result.screen_height = height;
      result.width = (obj.width * width) / obj.screen_width;
      result.height = (obj.height * height) / obj.screen_height;
      result.workstation_id = obj.workstation_id;
      result.id = obj.id ? obj.id : null;
      result.title = obj.title ? obj.title : null;

      return result;
    },
    closePinch() {
      this.pinch = false;
    },
    getWorkstationDetails: function (id) {
      const self = this;
      const api =
        self.$store.state.api + "workstation_details/?paginated=false";

      self.$_http
        .get(api)
        .then((response) => {
          self.details = response.data.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
  components: {
    VueDragResize,
  },
  mounted() {
    const self = this;
    let id = self.$route.params.id;
    self.getConfiguration();
  },
};
</script>

<style scoped>
.vdr {
  align-items: center;
  background: rgba(13, 80, 139, 0.4);
  /* border: 3px solid #0d508b; */
  border-radius: 20px;
}

.top-container {
  width: 100%;
  height: auto;
  position: relative;
}
.top-container img {
  width: 100%;
  height: auto;
  position: relative;
}

.btn {
  font-size: 0.6rem;
  padding: 6% 12%;
}

.btn-primary2 {
  text-align: center;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.centered {
  text-align: center;
}

div.verticalAlign {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  /* altura necessária */
  height: 240px;
  padding: 20px;
}
input[type="checkbox"] {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

input[type="checkbox"] + label {
  font-size: 12px;
  padding: 2px 0 0;
  width: 100%;
  max-height: 100%;
  border: 2px solid #4b2e8f;
  border-radius: 0.25rem;
}
input[type="checkbox"]:checked + label {
  border: 2px solid #4b2e8f;
  color: #fff;
  background-color: #4b2e8f;
  font-style: normal;
}
</style>
